import HeaderAdmin from "../components/HeaderAdmin";
import { useEffect, useState } from "react";
import CardGroupGray from "../components/CardGroupGray";
import ModalAddGroup from "../components/ModalAddGroup";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { baseHeaders, baseUrl, months } from "../utils/utils";
registerLocale("ru", ru);

function GroupsPage() {
  const [isOpen, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [modalActive, setModalActive] = useState(false);
  const [monthId, setMonthId] = useState(new Date().getMonth() + 1);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (monthId) {
      fetch(`${baseUrl}/admin/groups?month=${monthId}`, {
        headers: {
          ...baseHeaders,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setGroups(data.items);
        });
    }
  }, [monthId]);

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="group-title-wrap">
        <span className="group-title">Группы</span>
        <svg
          onClick={() => setModalActive(true)}
          className="btn-plus"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="2" fill="#1C0A3F" />
          <path d="M21 10H19V19H10V21H19V30H21V21H30V19H21V10Z" fill="#fff" />
        </svg>
      </div>
      <div className="calendar-wrap">
        <select
          onChange={(event) => setMonthId(event.target.value)}
          className="month-picker-select"
          value={monthId}
        >
          <option selected disabled hidden value="0">
            Выбрать месяц
          </option>
          {months.map((item) => (
            <option value={item.id} className="month-picker-option">
              {item.name}
            </option>
          ))}
        </select>
      </div>

      {monthId === 0 ? (
        <div className="text">
          Выберите месяц, чтобы посмотреть
          <br />
          актуальные заезды
        </div>
      ) : (
        <div className="card-container">
          {groups?.map((item) => (
            <CardGroupGray item={item} />
          ))}
        </div>
      )}

      <ModalAddGroup active={modalActive} setActive={setModalActive} />
    </>
  );
}

export default GroupsPage;
