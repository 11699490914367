import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { baseUrl, baseHeaders } from "../utils/utils";
import { useEffect, useState } from "react";

function ModalEditDish({ editDish, setEditDish, renderDishes, dishes }) {
  console.log(dishes);
  const [categ, setCateg] = useState([]);

  const validationsSchema = Yup.object().shape({
    name: Yup.string().required("Поле не заполнено"),
    dish_category_id: Yup.string().required("Категория блюда не выбрана"),
  });

  function sendEditDish(values) {
    fetch(`${baseUrl}/admin/dishes/${editDish.id}`, {
      method: "PATCH",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((data) => {
        renderDishes();
        setEditDish(false);
      });
  }

  function getCateg() {
    fetch(`${baseUrl}/admin/menu/types/category`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCateg(data.items);
      });
  }

  useEffect(() => {
    getCateg();
  }, []);

  return (
    <div
      className={
        editDish ? "modal-wrap-add-event active" : "modal-wrap-add-event"
      }
    >
      <div className="modal-add-event">
        <div className="modal-style">
          <div className="dishes-title-wrap">
            <span className="modal-add-event-title">Редактировать блюдо</span>
            <svg
              onClick={() => setEditDish(false)}
              className="add-event-btn-close"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
                fill="#1C0A3F"
              />
            </svg>
          </div>
          <Formik
            initialValues={{
              dish_category_id: editDish.dish_category_id,
              name: editDish.name,
              description: editDish.description,
            }}
            enableReinitialize={true}
            onSubmit={sendEditDish}
            validationSchema={validationsSchema}
          >
            {({ dirty, isValid }) => (
              <Form>
                <div className="flex-column">
                  <label
                    htmlFor={"dish_category_id"}
                    className="label-style-person"
                  >
                    Категория
                  </label>
                  <Field
                    as="select"
                    className="add-event-repeat select-dish"
                    name={"dish_category_id"}
                    placeholder="Выберите категорию"
                  >
                    {categ.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </Field>
                  <ErrorMessage
                    component={`p`}
                    name={`dish_category_id`}
                    className="err_message"
                  />
                </div>
                <div className="flex-column">
                  <label htmlFor={"name"} className="label-style-person">
                    Название
                  </label>
                  <Field
                    className="add-event-name name-dishes"
                    name={"name"}
                    placeholder="Назовите блюдо"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`name`}
                    className="err_message"
                  />
                </div>
                <div className="flex-column margin-bottom">
                  <label htmlFor={"description"} className="label-style-person">
                    Описание
                  </label>
                  <Field
                    as="textarea"
                    className="add-event-descr"
                    name={"description"}
                    placeholder="Опишите блюдо"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`description`}
                    className="err_message"
                  />
                </div>
                <div className="btn-event-wrap">
                  <button
                    onClick={() => setEditDish(false)}
                    className="btn-event-cancel"
                  >
                    Отменить
                  </button>
                  <button
                    type="submit"
                    className={
                      !(dirty && isValid)
                        ? "btn-event-save"
                        : "btn-event-save active"
                    }
                  >
                    Сохранить
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ModalEditDish;
