import React from "react";

function Menu() {
  return (
    <div className="rest">
      <span className="menu-title">Меню</span>
      <span className="menu-edit">Сформировать</span>
    </div>
  );
}

export default Menu;
