import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import { baseHeaders, baseUrl } from "../utils/utils";

function ModalEditPerson({
  editPerson,
  setEditPerson,
  getPerson,
  getInfoHouses,
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const location = useLocation();
  const [houses, setHouses] = useState([]);

  const validationsSchema = Yup.object().shape({
    first_name: Yup.string().required("Поле не заполнено"),
    last_name: Yup.string().required("Поле не заполнено"),
    surname: Yup.string().required("Поле не заполнено"),
    // start_date: Yup.string().required("Дата заезда не выбрана"),
    // end_date: Yup.string().required("Дата выезда не выбрана"),
    house_id: Yup.string().required("Дом не выбран"),
  });

  useEffect(() => {
    setStartDate(
      editPerson?.start_date ? new Date(editPerson?.start_date) : new Date()
    );
    setEndDate(
      editPerson?.end_date ? new Date(editPerson?.end_date) : new Date()
    );
  }, [editPerson?.start_date]);

  function sendEditPerson(values) {
    fetch(`${baseUrl}/admin/users/${editPerson?.id}`, {
      method: "PATCH",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        ...values,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setEditPerson(data);
        if (location.pathname.startsWith("/person_descr")) {
          getPerson();
        } else {
          getInfoHouses();
        }
        setEditPerson(false);
      });
  }

  useEffect(() => {
    fetch(`${baseUrl}/admin/houses/all/free`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setHouses(data.items);
      });
  }, []);

  return (
    <div
      className={
        editPerson ? "modal-wrap-add-group active" : "modal-wrap-add-group"
      }
    >
      <div className="modal-add-group">
        <div className="flex modal-style">
          <span className="modal-add-group-title">Редактировать гостя</span>
          <svg
            onClick={() => setEditPerson(false)}
            className="add-person-btn-close"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="
#877E99"
            />
          </svg>
        </div>
        <Formik
          initialValues={{
            first_name: editPerson?.first_name,
            last_name: editPerson?.last_name,
            surname: editPerson?.surname,
            role: "GUEST",

            group_id: +editPerson?.group_id,
            house_id: editPerson?.house_id,
          }}
          enableReinitialize={true}
          validationSchema={validationsSchema}
          onSubmit={sendEditPerson}
        >
          {({ setFieldValue, errors, touched, dirty, isValid }) => (
            <Form className="formwrap-add-person">
              <div className="fio-wrap flex">
                <div className="margin-right">
                  <label htmlFor={"last_name"} className="label-style-person">
                    Фамилия
                  </label>
                  <br />
                  <Field
                    className={
                      errors.last_name && touched.last_name
                        ? "add-person-fio error"
                        : "add-person-fio"
                    }
                    name={"last_name"}
                    placeholder="Иванов"
                    autocomplete="off"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`last_name`}
                    className="err_message"
                  />
                </div>
                <div className="margin-right">
                  <label htmlFor={"first_name"} className="label-style-person">
                    Имя
                  </label>
                  <br />
                  <Field
                    className={
                      errors.first_name && touched.first_name
                        ? "add-person-fio error"
                        : "add-person-fio"
                    }
                    name={"first_name"}
                    placeholder="Иван"
                    autocomplete="off"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`first_name`}
                    className="err_message"
                  />
                </div>
                <div className="margin-right">
                  <label htmlFor={"surname"} className="label-style-person">
                    Отчество
                  </label>
                  <br />
                  <Field
                    className={
                      errors.surname && touched.surname
                        ? "add-person-fio error"
                        : "add-person-fio"
                    }
                    name={"surname"}
                    placeholder="Иванович"
                    autocomplete="off"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`surname`}
                    className="err_message"
                  />
                </div>
              </div>

              <div className="flex wrap-datepicker">
                <div className="flex-start">
                  <label htmlFor={"start_date"} className="label-style-person">
                    Дата заезда
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setFieldValue(
                        "start_date",
                        date.toISOString().split("T")[0]
                      );
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date(editPerson?.group?.start_date)}
                    maxDate={new Date(editPerson?.group?.end_date)}
                    name={"start_date"}
                    locale="ru"
                    dateFormat={"dd.MM.yyyy"}
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`start_date`}
                    className="err_message"
                  />
                </div>
                <div className="flex-start">
                  <label htmlFor={"end_date"} className="label-style-person">
                    Дата выезда
                  </label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      setFieldValue(
                        "end_date",
                        date.toISOString().split("T")[0]
                      );
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date(editPerson?.group?.start_date)}
                    maxDate={new Date(editPerson?.group?.end_date)}
                    name={"end_date"}
                    locale="ru"
                    dateFormat={"dd.MM.yyyy"}
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`end_date`}
                    className="err_message"
                  />
                </div>
              </div>

              <div className="flex">
                <div className="home-room">
                  <label htmlFor={"house_id"} className="label-style-person">
                    Дом
                  </label>
                  <br />
                  <Field
                    onChange={(event) => {
                      setFieldValue("house_id", event.target.value);
                    }}
                    className="select-home"
                    as="select"
                    name={"house_id"}
                    placeholder="Выберите дом"
                  >
                    {houses?.map((item) => (
                      <option className="style-option" value={item.id}>
                        {item.number}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    component={`p`}
                    name={`house_id`}
                    className="err_message"
                  />
                </div>
              </div>

              <div className="btn-wrap-add-person">
                <button
                  className="btn-add-person-cancel"
                  onClick={() => setEditPerson(false)}
                >
                  Отменить
                </button>
                <button
                  type="submit"
                  className={
                    !(dirty && isValid)
                      ? "btn-add-person-save"
                      : "btn-add-person-save-active"
                  }
                >
                  Сохранить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ModalEditPerson;
