function Stars({ item }) {
  return (
    <>
      <svg
        className="review-star-margin"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={item.stars >= 1 ? "review-star active" : "review-star"}
          d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
          fill="#CFD0CA"
        />
      </svg>
      <svg
        className="review-star-margin"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={item.stars >= 2 ? "review-star active" : "review-star"}
          d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
          fill="#CFD0CA"
        />
      </svg>
      <svg
        className="review-star-margin"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={item.stars >= 3 ? "review-star active" : "review-star"}
          d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
          fill="#CFD0CA"
        />
      </svg>
      <svg
        className="review-star-margin"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={item.stars >= 4 ? "review-star active" : "review-star"}
          d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
          fill="#CFD0CA"
        />
      </svg>
      <svg
        className="review-star-margin"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={item.stars >= 5 ? "review-star active" : "review-star"}
          d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
          fill="#CFD0CA"
        />
      </svg>
      {/* <svg
        className="review-star-margin"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={item.stars >= 6 ? "review-star active" : "review-star"}
          d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
          fill="#CFD0CA"
        />
      </svg>
      <svg
        className="review-star-margin"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={item.stars >= 7 ? "review-star active" : "review-star"}
          d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
          fill="#CFD0CA"
        />
      </svg>
      <svg
        className="review-star-margin"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={item.stars >= 8 ? "review-star active" : "review-star"}
          d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
          fill="#CFD0CA"
        />
      </svg>
      <svg
        className="review-star-margin"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={item.stars >= 9 ? "review-star active" : "review-star"}
          d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
          fill="#CFD0CA"
        />
      </svg>
      <svg
        className="review-star-margin"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={item.stars >= 10 ? "review-star active" : "review-star"}
          d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
          fill="#CFD0CA"
        />
      </svg> */}
    </>
  );
}

export default Stars;
