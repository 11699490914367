import code from "./../images/code.png";
import edit from "./../images/edit-descr.png";
import plus from "./../images/plus.png";
import userFree from "./../images/user-free.png";

function HouseUser({ user, setEditPerson }) {
  return user ? (
    <div className="user">
      <img className="code-img" src={code} alt="code" />

      <div className="code-fio flex-column">
        <span className="user-fio">
          {user.last_name} {user.first_name} {user.surname}
        </span>
        <span className="user-code">
          {user.id}
          {user.access_code}
        </span>
      </div>
      <img
        className="edit-img"
        src={edit}
        alt="edit"
        onClick={() => setEditPerson(user)}
      />
    </div>
  ) : (
    <div className="user">
      <img className="user-free" src={userFree} alt="free" />
      <div className="code-fio flex-column">
        <span className="free">Свободно</span>
      </div>

      <img className="plus-user" src={plus} alt="plus" />
    </div>
  );
}

export default HouseUser;
