import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { baseHeaders, baseUrl } from "../utils/utils";
import DatePicker from "react-datepicker";
import * as Yup from "yup";

function ModalSelectEvent({
  select,
  setSelect,
  setActive,
  getCardsEvent,
  events,
}) {
  const [startDate, setStartDate] = useState(new Date());

  const validationsSchema = Yup.object().shape({
    event_id: Yup.string().required("Вы не выбрали мероприятие"),
    event_date: Yup.string().required("Дата проведения не выбрана"),
    repeat: Yup.string().required("Поле не заполнено"),
  });

  function sendSelect(values, { resetForm }) {
    if (values.repeat === "null") {
      values.repeat = null;
    }
    fetch(`${baseUrl}/admin/events/${values.event_id}/add`, {
      method: "POST",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((data) => {
        setSelect(false);
        setActive(false);
        getCardsEvent();
        resetForm();
      });
  }

  return (
    <div
      className={
        select ? "modal-wrap-add-event active" : "modal-wrap-add-event"
      }
    >
      <div className="modal-select-event">
        <div className="event-wrap margin-bottom">
          <span className="create-event-title">Добавить мероприятие</span>
          <svg
            onClick={() => setSelect(false)}
            className="add-event-btn-close"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="#1C0A3F"
            />
          </svg>
        </div>
        <span className="select-event-label">Мероприятие</span>
        <Formik
          initialValues={{
            event_id: events[0]?.id,
            event_date: "",
            repeat: "",
          }}
          onSubmit={sendSelect}
          enableReinitialize={true}
          validationSchema={validationsSchema}
        >
          {({ setFieldValue, dirty, isValid }) => (
            <Form>
              <Field
                name="event_id"
                id="event_id"
                className="select-event"
                as="select"
              >
                {events?.map((item) => (
                  <option value={item.id}>{item?.name}</option>
                ))}
              </Field>
              <div className="flex margin-bottom">
                <div className="flex-column">
                  <label htmlFor={"event_date"} className="label-style-person">
                    Дата проведения
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setFieldValue(
                        "event_date",
                        date.toISOString().split("T")[0]
                      );
                    }}
                    selectsStart
                    // minDate={startDate}
                    // startDate={startDate}
                    // endDate={endDate}
                    locale="ru"
                    dateFormat={"dd.MM.yyyy"}
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`event_date`}
                    className="err_message"
                  />
                </div>
                <div className="flex-column">
                  <label htmlFor={"repeat"} className="label-style-person">
                    Повтор
                  </label>
                  <Field
                    as="select"
                    className="add-event-repeat"
                    name={"repeat"}
                    placeholder="Выберите период"
                  >
                    <option></option>
                    <option value="null">Никогда</option>
                    <option value="EVERY_DAY">Каждый день</option>
                    <option value="EVERY_WEEK">Каждую неделю</option>
                    <option value="EVERY_TWO_WEEKS">Каждые две недели</option>
                    <option value="EVERY_MONTH">Каждый месяц</option>
                    <option value="EVERY_YEAR">Каждый год</option>
                  </Field>
                  <ErrorMessage
                    component={`p`}
                    name={`repeat`}
                    className="err_message"
                  />
                </div>
              </div>
              <div className="btn-event-wrap">
                <button
                  onClick={() => setSelect(false)}
                  className="btn-event-cancel"
                >
                  Отменить
                </button>
                <button
                  type="submit"
                  className={
                    !(dirty && isValid)
                      ? "btn-event-save"
                      : "btn-event-save active"
                  }
                >
                  Сохранить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ModalSelectEvent;
