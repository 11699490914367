import HeaderAdmin from "./../components/HeaderAdmin";
import edit from "./../images/edit-descr.png";
import code from "./../images/code.png";
import calendar from "./../images/calendar.png";
import home from "./../images/home.png";
import { baseHeaders, baseUrl, parseDate } from "../utils/utils";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ModalEditPerson from "../components/ModalEditPerson";

function PersonPage() {
  const [isOpen, setOpen] = useState(false);
  const [person, setPerson] = useState();
  const { id } = useParams();
  const [editPerson, setEditPerson] = useState(null);

  function getPerson() {
    fetch(`${baseUrl}/admin/users/${id}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPerson(data);
      });
  }

  useEffect(() => {
    getPerson();
  }, [id]);

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="descr-title-wrap">
        <span className="descr-title">
          {person?.last_name} {person?.first_name} {person?.surname}
        </span>
        <img
          onClick={() => setEditPerson(person)}
          className="btn-descr-edit"
          src={edit}
          alt="edit"
        />
      </div>

      <div className="person-info">
        <div className="person-code">
          <img className="person-icon" src={code} alt="code" />
          <div className="flex-column">
            <span className="bold">Код доступа</span>
            <span>{person?.full_access_code}</span>
          </div>
        </div>
        <div className="person-date">
          <img className="person-icon" src={calendar} alt="calendar" />
          <div className="flex-column">
            <span className="bold">Даты заезда</span>
            <span>
              {person && parseDate(person?.start_date)}-
              {person && parseDate(person?.end_date)}
            </span>
          </div>
        </div>
        <div className="flex person-home">
          <img className="person-icon" src={home} alt="home" />
          <div className="flex-column">
            <span className="bold">Дом</span>
            <span>{person?.house?.number}</span>
          </div>
        </div>
      </div>

      <ModalEditPerson
        editPerson={editPerson}
        setEditPerson={setEditPerson}
        getPerson={getPerson}
        setPerson={setPerson}
      />
    </>
  );
}

export default PersonPage;
