import { useNavigate, useParams } from "react-router-dom";
import { baseHeaders, baseUrl } from "../utils/utils";

function ModalCancelEvent({ cancelEvent, setCancelEvent }) {
  const { id } = useParams();
  const navigate = useNavigate();

  function cancelEventb() {
    fetch(`${baseUrl}/admin/events/${id}/remove`, {
      method: "POST",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(),
    })
      .then((res) => res.json())
      .then((data) => {
        setCancelEvent(false);
        navigate("/events");
      });
  }

  return (
    <div
      class={
        cancelEvent ? "modal-wrap-add-event active" : "modal-wrap-add-event"
      }
    >
      <div className="modal-add-event">
        <div className="modal-style-delete-person">
          <span className="modal-delete-person-title">Отмена мероприятия</span>
          <svg
            onClick={() => setCancelEvent(false)}
            className="add-person-btn-close"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="#494B42"
            />
          </svg>
        </div>
        <div className="center">
          <p className="delete-person-question">
            Вы точно хотите отменить мероприятие на 03.09.2023?
          </p>
          <p className="delete-person-text">
            Назначить мероприятие обратно можно через меню добавления
          </p>
        </div>
        <div className="btn-wrap-delete-person">
          <button onClick={cancelEventb} className="btn-cancel-event">
            Да
          </button>
          <button
            onClick={() => setCancelEvent(false)}
            className="btn-not-cancel"
          >
            Нет
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalCancelEvent;
