import { useEffect, useState } from "react";
import HeaderAdmin from "../components/HeaderAdmin";
import { useNavigate, useParams } from "react-router-dom";
import PersonMenu from "../components/PersonMenu";
import { baseUrl, baseHeaders, parseDate } from "../utils/utils";

function GroupChoiceMenu() {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const [guestChoice, setGuestChoice] = useState([]);
  const [day, setDay] = useState(1);
  const { start_date, end_date } = useParams();

  function getChoices() {
    fetch(
      `${baseUrl}/admin/menu/choices?day=${day}&start_date=${start_date}&end_date=${end_date}`,
      {
        headers: {
          ...baseHeaders,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setGuestChoice(data);
      });
  }

  useEffect(() => {
    getChoices();
  }, [day]);

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="search-course-wrap repeat-menu-wrap">
        <h1 className="dishes-title">Меню</h1>
      </div>
      <div className="wrap-create-menu">
        <div className="create-menu" onClick={() => navigate("/menu")}>
          Создание меню
        </div>
        <div
          className="choise-users active"
          onClick={() => navigate("/group_menu/:id")}
        >
          Выбор гостей
        </div>
      </div>
      <div className="date-group-menu">
        {parseDate(start_date)} – {parseDate(end_date)}
      </div>
      <div className="wrap-create-menu">
        <select
          onChange={(event) => {
            setDay(event.target.value);
          }}
          className="month-picker-select day-picker-select"
        >
          {[...new Array(10).fill(null)].map((element, index) => (
            <option value={index + 1}>День {index + 1}</option>
          ))}
        </select>
      </div>

      <div className="list-wrap">
        <div className="header-list-wrap">
          <span className="list-item list-item-home">Дом</span>

          <span className="list-item list-item-fio item-fio-menu">ФИО</span>
          <span className="list-item list-item-mail item-breakfast">
            Завтрак
          </span>
          <span className="list-item list-item-start item-lunch">Обед</span>
          <span className="list-item list-item-end item-dinner">Ужин</span>
        </div>
        <div className="container-person-menu">
          {guestChoice?.items?.map((item) => (
            <PersonMenu item={item} />
          ))}
        </div>
      </div>
    </>
  );
}

export default GroupChoiceMenu;
