import HouseUser from "./HouseUser";

function HouseCard({ item, setEditPerson }) {
  const arr = [...item.users, ...new Array(item.capacity - item.users.length)];

  return (
    <div className="house-card">
      <div
        className={
          item.capacity - item.users.length > 0
            ? "house-header free-house flex-column"
            : "house-header flex-column"
        }
      >
        <span>{item.number}</span>
      </div>
      <div className="house-users">
        {arr.map((user) => (
          <HouseUser user={user} setEditPerson={setEditPerson} />
        ))}
      </div>
    </div>
  );
}

export default HouseCard;
