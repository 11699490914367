import { useEffect, useState } from "react";
import HeaderAdmin from "../components/HeaderAdmin";
import {
  baseHeaders,
  baseUrl,
  compareData,
  months,
  parseDate,
} from "../utils/utils";
import ModalAddEvent from "../components/ModalAddEvent";
import EventCardDate from "../components/EventCardDate";
import ModalEditEventBtn from "../components/ModalEditEventBtn";

function EventsPage({ editEvent, setEditEvent }) {
  const [isOpen, setOpen] = useState(false);
  const [monthId, setMonthId] = useState(new Date().getMonth() + 1);
  const [active, setActive] = useState();
  const [merop, setMerop] = useState();
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [groups, setGroups] = useState([]);
  const [groupId, setGroupId] = useState(0);

  function getCardsEvent() {
    fetch(
      `${baseUrl}/admin/events/all/scheduled?month=${monthId}&limit=6&offset=${offset}${
        groupId ? `&group_id=${groupId}` : ""
      }`,
      {
        headers: {
          ...baseHeaders,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setMerop(compareData(data));
        setTotalPages(Math.ceil(data.total_pages));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const controller = new AbortController();

    fetch(
      `${baseUrl}/admin/events/all/scheduled?month=${monthId}&limit=6&offset=${offset}${
        groupId ? `&group_id=${groupId}` : ""
      }`,
      {
        headers: {
          ...baseHeaders,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        signal: controller.signal,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setMerop(compareData(data));
        setTotalPages(Math.ceil(data.total_pages));
      })
      .catch((err) => {
        console.log(err);
      });

    return () => controller.abort();
  }, [monthId, offset, groupId]);

  useEffect(() => {
    fetch(`${baseUrl}/admin/groups?month=${monthId}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setGroups(data.items);
      });
  }, [monthId]);

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="group-title-wrap">
        <span className="group-title">Мероприятия</span>
        <svg
          onClick={() => setActive(true)}
          className="btn-plus"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="2" fill="#1C0A3F" />
          <path d="M21 10H19V19H10V21H19V30H21V21H30V19H21V10Z" fill="#fff" />
        </svg>
      </div>

      <div className="filter-wrap">
        <div className="calendar-wrap">
          <select
            onChange={(event) => setMonthId(event.target.value)}
            className="month-picker-select"
            value={monthId}
          >
            {months.map((item) => (
              <option value={item.id} className="month-picker-option">
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="chekin-date-wrap">
          <select
            value={groupId}
            onChange={(event) => setGroupId(event.target.value)}
            className="month-picker-select"
          >
            <option className="test-option" selected disabled hidden value="0">
              Выберите заезд
            </option>
            {groups?.map((item) => (
              <option value={item.id}>
                {parseDate(item.start_date)}-{parseDate(item.end_date)}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="event-card-container">
        {merop?.map((item) => (
          <EventCardDate
            date={item.date}
            items={item.items}
            editEvent={editEvent}
            setEditEvent={setEditEvent}
          />
        ))}
      </div>

      <div className="pagination-container">
        <ul className="pagination-list">
          <button
            className="btn-left"
            disabled={offset <= 0}
            onClick={() => setOffset((prev) => prev - 6)}
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.8438 7H4.67375L10.2638 1.41L8.84375 0L0.84375 8L8.84375 16L10.2537 14.59L4.67375 9H16.8438V7Z"
                fill="#494B42"
              />
            </svg>
          </button>
          <li className="page-number">{Math.ceil(offset / 6) + 1}</li>
          <button
            className="btn-right"
            disabled={Math.ceil(offset / 6) + 1 === totalPages}
            onClick={() => setOffset((prev) => prev + 6)}
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.3633 4L10.9533 5.41L16.5333 11H4.36328V13H16.5333L10.9533 18.59L12.3633 20L20.3633 12L12.3633 4Z"
                fill="#494B42"
              />
            </svg>
          </button>
        </ul>
      </div>

      <ModalAddEvent
        active={active}
        setActive={setActive}
        getCardsEvent={getCardsEvent}
      />
      <ModalEditEventBtn
        editEvent={editEvent}
        setEditEvent={setEditEvent}
        getCardsEvent={getCardsEvent}
      />
    </>
  );
}

export default EventsPage;
