import { useEffect, useState } from "react";
import HeaderAdmin from "../components/HeaderAdmin";
import edit from "../images/edit-descr.png";
import cancel from "../images/cancel-descr.png";
import del from "../images/delete-descr.png";
import ModalDeleteEvent from "../components/ModalDeleteEvent";
import ModalCancelEvent from "../components/ModalCancelEvent";
import ModalEditEventBtn from "../components/ModalEditEventBtn";
import { useParams } from "react-router-dom";
import { baseHeaders, baseUrl, getTime, parseDate } from "../utils/utils";

function EventDescr({ editEvent, setEditEvent }) {
  const [isOpen, setOpen] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [cancelEvent, setCancelEvent] = useState(false);
  const { id } = useParams();
  const [event, setEvent] = useState(null);

  function getDescr() {
    fetch(`${baseUrl}/admin/events/${id}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setEvent(data);
      });
  }
  console.log(event);
  useEffect(() => {
    getDescr();
  }, [id]);

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="descr-title-wrap">
        <span className="descr-title">{event?.name}</span>
        <img
          onClick={() => setEditEvent(event)}
          className="btn-descr-edit"
          src={edit}
          alt="edit"
        />
        <img
          onClick={() => setCancelEvent(true)}
          className="btn-descr-cancel"
          src={cancel}
          alt="cancel"
        />
        <span className="strelka">&#8592; отменить мероприятие</span>
        <span className="strelka-right">
          удалить мероприятие навсегда &#8594;
        </span>
        <img
          onClick={() => setDeleteEvent(true)}
          className="btn-descr-delete"
          src={del}
          alt="del"
        />
      </div>
      <div className="descr-time-date">
        <span className="descr-time">
          {event?.time_start.toLocaleString().slice(0, -3)}-
          {event?.time_end.toLocaleString().slice(0, -3)}
        </span>
        <span className="descr-date">{event && parseDate(event?.date)}</span>
      </div>
      <div className="descr-info">
        <span className="descr-hour descr-item">
          {event && getTime(event?.time_start, event?.time_end)}ч
        </span>
        <span className="descr-place descr-item">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.6654 6.60606C12.6654 10.0606 7.9987 14.6667 7.9987 14.6667C7.9987 14.6667 3.33203 10.0606 3.33203 6.60606C3.33203 3.95621 5.42137 2 7.9987 2C10.576 2 12.6654 3.95621 12.6654 6.60606ZM7.9987 8.66667C9.10327 8.66667 9.9987 7.77124 9.9987 6.66667C9.9987 5.5621 9.10327 4.66667 7.9987 4.66667C6.89413 4.66667 5.9987 5.5621 5.9987 6.66667C5.9987 7.77124 6.89413 8.66667 7.9987 8.66667Z"
              fill="#4C4360"
            />
          </svg>{" "}
          Место проведения - {event?.event_place}
        </span>
        <span className="descr-meet descr-item">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.6654 6.60606C12.6654 10.0606 7.9987 14.6667 7.9987 14.6667C7.9987 14.6667 3.33203 10.0606 3.33203 6.60606C3.33203 3.95621 5.42137 2 7.9987 2C10.576 2 12.6654 3.95621 12.6654 6.60606ZM7.9987 8.66667C9.10327 8.66667 9.9987 7.77124 9.9987 6.66667C9.9987 5.5621 9.10327 4.66667 7.9987 4.66667C6.89413 4.66667 5.9987 5.5621 5.9987 6.66667C5.9987 7.77124 6.89413 8.66667 7.9987 8.66667Z"
              fill="#4C4360"
            />
          </svg>{" "}
          Место встречи - {event?.gathering_place}
        </span>
      </div>
      <div className="descr-text">{event?.description}</div>

      <ModalEditEventBtn
        editEvent={editEvent}
        setEditEvent={setEditEvent}
        getDescr={getDescr}
      />
      <ModalDeleteEvent
        deleteEvent={deleteEvent}
        setDeleteEvent={setDeleteEvent}
      />
      <ModalCancelEvent
        cancelEvent={cancelEvent}
        setCancelEvent={setCancelEvent}
      />
    </>
  );
}

export default EventDescr;
