import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "./../utils/utils";

function Login() {
  const navigate = useNavigate();
  const goNav = () => navigate("/", { replace: true });

  const validationsSchema = Yup.object().shape({
    username: Yup.string()
      // .email('Почта введена неверно')
      .required("Поле не заполнено"),
    password: Yup.string()
      .min(8, "Минимум 8 символов")
      .required("Поле не заполнено"),
  });

  const [visible, setVisible] = useState(false);

  function handleVisible() {
    setVisible((prev) => !prev);
  }

  function handleSubmit(values) {
    let formData = new FormData();
    for (let key in values) {
      formData.append(key, values[key]);
    }

    fetch(`${baseUrl}/admin/auth/login`, {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(formData),
      grant_type: "password",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.detail) {
          alert("Неверный логин или пароль");
        } else {
          localStorage.setItem("token", data.access_token);
          goNav();
        }
      });
  }

  return (
    <div className="form">
      <h1 className="form_title">Войти в систему</h1>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validateOnBlur
        onSubmit={handleSubmit}
        validationSchema={validationsSchema}
      >
        {({ values, errors, touched, isValid, dirty }) => (
          <Form className="input_wrap">
            <label htmlFor={`username`} className="label_style">
              Логин
            </label>
            <br />
            <Field
              className={
                errors.username && touched.username
                  ? "input_style-err"
                  : "input_style"
              }
              // type={`email`}
              name={`username`}
              placeholder="admin@gazprom-neft.ru"
            />
            <ErrorMessage
              component={`p`}
              name={`username`}
              className="err_message"
            />
            <label htmlFor={`password`} className="label_style label_password">
              Пароль
            </label>
            <br />
            <div className="test2">
              <Field
                className={
                  errors.password && touched.password
                    ? "input_style-err"
                    : "input_style input_password"
                }
                type={visible ? `text` : `password`}
                name={`password`}
                placeholder="********"
              />
              {!visible ? (
                <svg
                  onClick={handleVisible}
                  className="test3"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z"
                    fill="#CFD0CA"
                  />
                </svg>
              ) : (
                <svg
                  onClick={handleVisible}
                  className="test4"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.48667 18.5847L4.90088 19.9989L20.4572 4.44253L19.043 3.02832L16.6285 5.44282C15.2073 4.83607 13.6431 4.50023 12 4.50023C7 4.50023 2.73 7.61023 1 12.0002C1.81175 14.0601 3.18274 15.8382 4.92596 17.1454L3.48667 18.5847ZM7.63386 14.4375L9.145 12.9263C9.05085 12.6348 9 12.3236 9 12.0002C9 10.3402 10.34 9.00023 12 9.00023C12.3234 9.00023 12.6346 9.05107 12.9261 9.14523L14.4372 7.63409C13.716 7.2304 12.8847 7.00023 12 7.00023C9.24 7.00023 7 9.24023 7 12.0002C7 12.885 7.23017 13.7163 7.63386 14.4375Z"
                    fill="#878980"
                  />
                  <path
                    d="M12 19.5002C10.8532 19.5002 9.7448 19.3366 8.69664 19.0315L10.8589 16.8692C11.2254 16.9549 11.6075 17.0002 12 17.0002C14.76 17.0002 17 14.7602 17 12.0002C17 11.6077 16.9547 11.2257 16.869 10.8591L20.0531 7.67502C21.3323 8.86884 22.3471 10.3434 23 12.0002C21.27 16.3902 17 19.5002 12 19.5002Z"
                    fill="#878980"
                  />
                </svg>
              )}
            </div>
            <ErrorMessage
              component={`p`}
              name={`password`}
              className="err_message"
            />
            <button className="btn_style" type={`submit`}>
              Войти
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Login;
