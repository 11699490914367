import { useEffect, useState } from "react";
import HeaderAdmin from "../components/HeaderAdmin";
import { baseUrl, baseHeaders, months } from "../utils/utils";
import CardGroupGray from "../components/CardGroupGray";

function GuestChoiceMenu() {
  const [isOpen, setOpen] = useState(false);
  const [monthId, setMonthId] = useState(new Date().getMonth() + 1);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (monthId) {
      fetch(`${baseUrl}/admin/groups?month=${monthId}`, {
        headers: {
          ...baseHeaders,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setGroups(data.items);
        });
    }
  }, [monthId]);

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="group-title-wrap">
        <span className="group-title">Ресторан</span>
      </div>
      <div className="calendar-wrap">
        <select
          onChange={(event) => setMonthId(event.target.value)}
          className="month-picker-select"
          value={monthId}
        >
          <option selected disabled hidden value="0">
            Выбрать месяц
          </option>
          {months.map((item) => (
            <option value={item.id} className="month-picker-option">
              {item.name}
            </option>
          ))}
        </select>
      </div>

      {monthId === 0 ? (
        <div className="text">
          Выберите месяц, чтобы посмотреть
          <br />
          актуальные заезды
        </div>
      ) : (
        <div className="card-container">
          {groups?.map((item) => (
            <CardGroupGray item={item} />
          ))}
        </div>
      )}
    </>
  );
}

export default GuestChoiceMenu;
