import addDishIcon from "./../images/addDishSmall.png";
import MenuCheckbox from "./MenuCheckbox";
import addBig from "./../images/addDishBig.png";
import MenuChoice from "./MenuChoice";
import ModalAddDish from "./ModalAddDish";
import ModalCancelDish from "./ModalCancelDish";
import ModalAddDishSmall from "./ModalAddDishSmaill";
import { baseUrl, baseHeaders } from "../utils/utils";

function MenuForm({
  addDish,
  setAddDish,
  cancelDish,
  setCancelDish,
  addDishSmall,
  setAddDishSmall,
  menu,
  setMenu,
  period,
  getMenu,
  timeStart,
  setTimeStart,
  timeEnd,
  setTimeEnd,
  day,
  forAll,
  setForAll,
  tea,
  setTea,
}) {
  function handleSubmit() {
    if (!timeStart || !timeEnd) {
      alert("Выберите время");
      return;
    }
    fetch(`${baseUrl}/admin/menu/select`, {
      method: "PUT",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        meal_type_id: period.toUpperCase(),
        dishes: [
          {
            type: "FOR_ALL",
            ids: forAll,
          },
          {
            type: "TEA_HOUSE",
            ids: tea,
          },
        ],
        day: day,
        start_time: timeStart,
        end_time: timeEnd,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        alert("Изменения сохранены");
      });
  }

  return (
    <>
      <div className="box">
        <div className="courses-time-wrap">
          <div className="flex-column margin">
            <label className="label-time" for="start-time">
              Начало
            </label>
            <input
              onChange={(event) => {
                setTimeStart(event.target.value);
              }}
              value={timeStart}
              name="start-time"
              id="course-start"
              className="input-time"
              type="time"
              placeholder="00:00"
            />
          </div>{" "}
          <div className="flex-column margin">
            <label className="label-time" for="end-time">
              Конец
            </label>
            <input
              onChange={(event) => {
                setTimeEnd(event.target.value);
              }}
              value={timeEnd}
              id="course-end"
              className="input-time"
              type="time"
              name="end-time"
            />
          </div>
        </div>
        <div className="btn-menu-wrap">
          <img
            onClick={() => setAddDishSmall(true)}
            className="btn-add-dish"
            src={addDishIcon}
            alt="alt"
          />
          <button onClick={handleSubmit} className="save-menu">
            Сохранить изменения, если изменилось время или выбор чекбоксов
          </button>
        </div>
        <div className="for-all">
          <h2 className="for-all-title">Для всех</h2>
          <div className="for-all-container">
            {menu?.for_all?.dishes?.map((item) => (
              <MenuCheckbox
                item={item}
                setIds={setForAll}
                ids={forAll}
                period={period}
                day={day}
              />
            ))}
          </div>
          <h2 className="for-all-title">На выбор</h2>
          <div className="choice-container">
            <img
              className="btn-add-dish-big"
              src={addBig}
              alt="alt"
              onClick={() => setAddDish(true)}
            />

            {menu?.choices?.map((item) => (
              <MenuChoice setCancelDish={setCancelDish} item={item} />
            ))}
          </div>
          {/* <h2 className="for-all-title">Чайный дом</h2>
          <div className="tea-house-container">
            {menu?.tea_house?.dishes?.map((item) => (
              <MenuCheckbox item={item} setIds={setTea} ids={tea} />
            ))}
          </div> */}
        </div>
      </div>
      <ModalAddDish
        addDish={addDish}
        setAddDish={setAddDish}
        period={period}
        day={day}
        getMenu={getMenu}
        setMenu={setMenu}
      />
      <ModalAddDishSmall
        addDishSmall={addDishSmall}
        setAddDishSmall={setAddDishSmall}
        period={period}
        day={day}
        getMenu={getMenu}
        setMenu={setMenu}
      />
      <ModalCancelDish
        cancelDish={cancelDish}
        setCancelDish={setCancelDish}
        menu={menu}
        getMenu={getMenu}
      />
    </>
  );
}

export default MenuForm;
