export const baseUrl = "https://glamp.altaiy.ru/api";

export const baseHeaders = {
  "Content-type": "application/json",
  Accept: "application/json",
};

export const parseDate = (data) => {
  const [year, month, day] = data.split("-");
  return `${day}.${month}.${year}`;
};

export const jsonDate = (data) => {
  const [year, month, day] = data.split(".");
  return `${year}-${month}-${day}`;
};

export const months = [
  {
    id: 1,
    name: "Январь",
  },
  {
    id: 2,
    name: "Февраль",
  },
  {
    id: 3,
    name: "Март",
  },
  {
    id: 4,
    name: "Апрель",
  },
  {
    id: 5,
    name: "Май",
  },
  {
    id: 6,
    name: "Июнь",
  },
  {
    id: 7,
    name: "Июль",
  },
  {
    id: 8,
    name: "Август",
  },
  {
    id: 9,
    name: "Сентябрь",
  },
  {
    id: 10,
    name: "Октябрь",
  },
  {
    id: 11,
    name: "Ноябрь",
  },
  {
    id: 12,
    name: "Декабрь",
  },
];

export const compareData = (data) => {
  let obj = {};
  let resultArr = [];
  data.items.forEach((element) => {
    obj[element.event_date] = [];
  });

  data.items.forEach((element) => {
    for (let key in obj) {
      if (key === element.event_date) {
        obj[key].push(element.event);
      }
    }
  });

  for (let key in obj) {
    resultArr.push({
      date: key,
      items: obj[key],
    });
  }
  return resultArr;
};

export const compareDataEvents = (data) => {
  let obj = {};
  let resultArr = [];
  data.items.forEach((element) => {
    obj[element.date] = [];
  });

  data.items.forEach((element) => {
    for (let key in obj) {
      if (key === element.date) {
        obj[key].push(element);
      }
    }
  });

  for (let key in obj) {
    resultArr.push({
      date: key,
      items: obj[key],
    });
  }
  return resultArr;
};

let getDate = (string) =>
  new Date(0, 0, 0, string.split(":")[0], string.split(":")[1]);
export const getTime = (firstDate, secondDate) => {
  let different = getDate(secondDate) - getDate(firstDate);

  let hours = Math.floor((different % 86400000) / 3600000);
  let minutes = Math.round(((different % 86400000) % 3600000) / 60000);
  let result = hours + "," + minutes;

  return result;
};

export const translateRepeat = {
  EVERY_DAY: "Каждый день",
  EVERY_WEEK: "Каждую неделю",
  EVERY_TWO_WEEKS: "Каждые две недели",
  EVERY_MONTH: "Каждый месяц",
  EVERY_YEAR: "Каждый год",
};

export const getSelectedIds = (arr) => {
  if (!arr) {
    return [];
  }
  return arr.reduce((newArr, el) => {
    if (el.selected) {
      newArr.push(el.id);
    }
    return newArr;
  }, []);
};

export const translateMealType = {
  BREAKFAST: "ЗАВТРАК",
  SOUP: "СУП",
  SALAD: "САЛАТ",
  HOT: "ГОРЯЧЕЕ",
  GARNISH: "ГАРНИР",
  SNACKS: "ЗАКУСКИ",
  COTTAGE_CHEESE: "ТВОРОГ",
  EGGS: "ЯЙЦА",
  PORRIDGE: "КАШИ",
  FOR_ALL: "ДЛЯ ВСЕХ",
  TEA_HOUSE: "ЧАЙНЫЙ ДОМИК",
};
