import { useEffect, useState } from "react";
import { baseHeaders, baseUrl } from "../utils/utils";
import HouseCard from "../components/HouseCard";
import closewindow from "./../images/closewindow.png";
import ModalEditPerson from "./ModalEditPerson";

function ModalHouses({ openHouses, setOpenHouses }) {
  const [houses, setHouses] = useState([]);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [editPerson, setEditPerson] = useState(null);

  function getInfoHouses() {
    fetch(`${baseUrl}/admin/houses?limit=8&offset=${offset}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setHouses(data);
        setTotalPages(data.total_pages);
      });
  }

  useEffect(() => {
    getInfoHouses();
  }, [offset]);

  return (
    <>
      <div
        className={
          openHouses ? "modal-wrap-houses active" : "modal-wrap-houses"
        }
      >
        <div className="test-relative">
          <div className="houses-container">
            {houses?.houses?.map((item) => (
              <HouseCard
                item={item}
                house={houses}
                setEditPerson={setEditPerson}
              />
            ))}
          </div>
          <div className="test-absolute">
            <img
              src={closewindow}
              alt="alt"
              onClick={() => setOpenHouses(false)}
              className="test-absolute-2"
            />
            <div className="pagination-container test-absolute">
              <ul className="pagination-list">
                <button
                  className="btn-left"
                  disabled={offset <= 0}
                  onClick={() => setOffset((prev) => prev - 8)}
                >
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.8438 7H4.67375L10.2638 1.41L8.84375 0L0.84375 8L8.84375 16L10.2537 14.59L4.67375 9H16.8438V7Z"
                      fill="#494B42"
                    />
                  </svg>
                </button>
                <li className="page-number">{Math.ceil(offset / 8) + 1}</li>
                <button
                  className="btn-right"
                  disabled={Math.ceil(offset / 8) + 1 === totalPages}
                  onClick={() => setOffset((prev) => prev + 8)}
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.3633 4L10.9533 5.41L16.5333 11H4.36328V13H16.5333L10.9533 18.59L12.3633 20L20.3633 12L12.3633 4Z"
                      fill="#494B42"
                    />
                  </svg>
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalEditPerson
        editPerson={editPerson}
        setEditPerson={setEditPerson}
        setHouses={setHouses}
        getInfoHouses={getInfoHouses}
      />
    </>
  );
}

export default ModalHouses;
