import editDish from "./../images/editDishes.png";
import deleteDish from "./../images/delete-item.png";

function DishesCard({ item, setEditDish, setDeleteDish }) {
  return (
    <div className="dishes-card">
      <span className="dishes-name-card">{item.name}</span>
      <span className="dishes-descr-card">{item.description}</span>
      <img
        className="dishes-edit"
        src={editDish}
        alt="alt"
        onClick={() => setEditDish(item)}
      />
      <img
        className="dishes-delete"
        src={deleteDish}
        alt="alt"
        onClick={() => setDeleteDish(item)}
      />
    </div>
  );
}

export default DishesCard;
