import { useEffect, useState } from "react";
import HeaderAdmin from "../components/HeaderAdmin";
import MenuForm from "../components/MenuForm";
import { useNavigate } from "react-router-dom";
import { baseUrl, baseHeaders, getSelectedIds } from "../utils/utils";

function MenuPage() {
  const [isOpen, setOpen] = useState(false);
  const [addDish, setAddDish] = useState(false);
  const [cancelDish, setCancelDish] = useState(false);
  const [addDishSmall, setAddDishSmall] = useState(false);
  const navigate = useNavigate();
  const [menu, setMenu] = useState([]);
  const [day, setDay] = useState(1);
  const [period, setPeriod] = useState("breakfast");
  const [timeStart, setTimeStart] = useState("");
  const [timeEnd, setTimeEnd] = useState("");
  const [forAll, setForAll] = useState([]);
  const [tea, setTea] = useState([]);

  function getMenu() {
    fetch(`${baseUrl}/admin/menu?day=${day}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setForAll(getSelectedIds(data[period].for_all.dishes));
        setTea(getSelectedIds(data[period].tea_house.dishes));
        setMenu(data[period]);
        setTimeStart(data[period].start_time);
        setTimeEnd(data[period].end_time);
      });
  }

  useEffect(() => {
    getMenu();
  }, [day, period]);

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="search-course-wrap repeat-menu-wrap">
        <h1 className="dishes-title">Меню</h1>
      </div>
      <div className="wrap-create-menu">
        <div className="create-menu active">Создание меню</div>
        <div className="choise-users" onClick={() => navigate("/guests_menu")}>
          Выбор гостей
        </div>
      </div>
      <div className="wrap-create-menu">
        <select
          onChange={(event) => {
            setDay(event.target.value);
          }}
          className="month-picker-select day-picker-select"
        >
          {[...new Array(10).fill(null)].map((element, index) => (
            <option className="month-picker-option" value={index + 1}>
              День {index + 1}
            </option>
          ))}
        </select>
      </div>
      <div className="period-wrap">
        <div
          onClick={() => setPeriod("breakfast")}
          className={`btn-item-period ${
            period === "breakfast" ? "active" : ""
          } breakfast`}
        >
          Завтрак
        </div>
        <div
          onClick={() => setPeriod("lunch")}
          className={`btn-item-period ${
            period === "lunch" ? "active" : ""
          } lunch`}
        >
          Обед
        </div>
        <div
          onClick={() => setPeriod("dinner")}
          className={`btn-item-period ${
            period === "dinner" ? "active" : ""
          } dinner`}
        >
          Ужин
        </div>
      </div>

      <MenuForm
        addDish={addDish}
        setAddDish={setAddDish}
        cancelDish={cancelDish}
        setCancelDish={setCancelDish}
        addDishSmall={addDishSmall}
        setAddDishSmall={setAddDishSmall}
        menu={menu}
        setMenu={setMenu}
        period={period}
        day={day}
        getMenu={getMenu}
        timeStart={timeStart}
        setTimeStart={setTimeStart}
        timeEnd={timeEnd}
        setTimeEnd={setTimeEnd}
        forAll={forAll}
        setForAll={setForAll}
        tea={tea}
        setTea={setTea}
      />
    </>
  );
}

export default MenuPage;
