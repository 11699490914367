import { useEffect, useState } from "react";
import HeaderAdmin from "../components/HeaderAdmin";
import FeedbackCard from "../components/FeedbackCard";
import { baseUrl, baseHeaders } from "../utils/utils";
import { useParams } from "react-router-dom";

function FeedbackDescr() {
  const [isOpen, setOpen] = useState(false);
  const [review, setReview] = useState([]);
  const { start_date, end_date } = useParams();

  function getReview() {
    fetch(
      `${baseUrl}/admin/reviews/all/dated?start_date=${start_date}&end_date=${end_date}`,
      {
        headers: {
          ...baseHeaders,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setReview(data);
      });
  }

  console.log(review.items);

  useEffect(() => {
    getReview();
  }, []);

  async function getTableReview() {
    try {
      await fetch(
        `${baseUrl}/admin/reviews/excel?start_date=${start_date}&end_date=${end_date}`,
        {
          headers: {
            // ...baseHeaders,
            // "Content-Type": "blob",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          // responseType: "blob",
        }
      )
        .then((res) => {
          if ((res.ok === true) & (res.status === 200)) {
            return res.blob();
          }
        })
        .then((data) => {
          const url = window.URL.createObjectURL(data);
          console.log(data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "reviews.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="group-title-wrap download">
        <span className="group-title group-title-feed">Обратная связь</span>

        {review?.items?.length > 0 ? (
          <button onClick={getTableReview} className="btn-download">
            Скачать таблицу с отзывами
          </button>
        ) : (
          ""
        )}
      </div>

      <div className="feedback-container">
        {review?.items?.map((item) => (
          <FeedbackCard item={item} />
        ))}
      </div>
    </>
  );
}

export default FeedbackDescr;
