import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import plus from "./../images/plus1.png";
import minus from "./../images/cancel-descr.png";
import { useEffect, useState } from "react";
import { baseHeaders, baseUrl } from "../utils/utils";

function ModalAddDish({ addDish, setAddDish, period, getMenu, day, setMenu }) {
  const validationsSchema = Yup.object().shape({
    dish_category_id: Yup.string().required("Категория блюда не выбрана"),
  });

  const [type, setType] = useState([]);
  const [categ, setCateg] = useState([]);
  const [meal, setMeal] = useState([]);
  const [alt, setAlt] = useState([0]);

  function getType() {
    fetch(`${baseUrl}/admin/menu/types/dish_type`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setType(data.items);
      });
  }

  useEffect(() => {
    getType();
  }, []);

  function getCateg() {
    fetch(`${baseUrl}/admin/menu/types/category`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCateg(data.items);
      });
  }

  useEffect(() => {
    getCateg();
  }, []);

  function getMeal(dish, category) {
    fetch(
      `${baseUrl}/admin/dishes/all/available?dish_type=${dish}&meal_type=${period.toUpperCase()}&category=${category}`,
      {
        headers: {
          ...baseHeaders,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setMeal(data.items);
        setAlt([data.items[0].id]);
      });
  }

  function handleChange(event, index) {
    let copy = Object.assign([], alt);
    copy[index] = +event.target.value;
    setAlt(copy);
  }

  function handleClick() {
    setAlt((prev) => [...prev, 0]);
  }

  function handleClickDelete() {
    setAlt((prev) => [...prev.slice(0, -1)]);
  }

  function sendChoice(values, { resetForm }) {
    fetch(`${baseUrl}/admin/menu/choice`, {
      method: "POST",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        meal_type_id: period.toUpperCase(),
        day: day,
        dishes_ids: alt,
        dish_category_id: values.dish_category_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setAddDish(false);
        getMenu();
        resetForm();
      })
      .catch((err) => {
        alert("Выбор не сохранился! Заполните пустое поле или удалите его.");
      });
  }

  return (
    <div
      className={
        addDish ? "modal-wrap-add-event active" : "modal-wrap-add-event"
      }
    >
      <div className="modal-add-event modal-add-dish">
        <div className="modal-style">
          <div className="dishes-title-wrap">
            <span className="modal-add-event-title">Добавить блюдо</span>
            <svg
              onClick={() => setAddDish(false)}
              className="add-event-btn-close"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
                fill="#1C0A3F"
              />
            </svg>
          </div>
          <Formik
            initialValues={{
              meal_type_id: "",
              dish_category_id: "",
              dishes_ids: "",
            }}
            validationSchema={validationsSchema}
            onSubmit={sendChoice}
          >
            {({ dirty, isValid, values, setFieldValue }) => (
              <Form>
                <div className="flex-column">
                  <label
                    htmlFor={"dish_type_id"}
                    className="label-style-person label-style-dish"
                  >
                    Тип блюда
                  </label>

                  <Field
                    disabled
                    as="select"
                    className="add-event-repeat select-dish add-dish-style"
                    name={"dish_type_id"}
                    placeholder="Выберите категорию"
                  >
                    {type.map((item) => {
                      if (item.id == "CHOICE") {
                        return <option value={item.id}>{item.name}</option>;
                      }
                    })}
                  </Field>

                  <ErrorMessage
                    component={`p`}
                    name={`dish_type_id`}
                    className="err_message"
                  />
                </div>
                <div className="flex-column">
                  <label
                    htmlFor={"dish_category_id"}
                    className="label-style-person label-style-dish"
                  >
                    Категория
                  </label>
                  <Field
                    onChange={(event) => {
                      setFieldValue("dish_category_id", event.target.value);
                      getMeal("CHOICE", event.target.value);
                    }}
                    as="select"
                    className="add-event-repeat select-dish add-dish-style"
                    name={"dish_category_id"}
                    placeholder="Выберите категорию"
                  >
                    <option>Выберите категорию блюда</option>
                    {categ.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </Field>
                  <ErrorMessage
                    component={`p`}
                    name={`dish_category_id`}
                    className="err_message"
                  />
                </div>
                <div className="flex-column">
                  <label
                    htmlFor={"dishes_ids"}
                    className="label-style-person label-style-dish"
                  >
                    Выберите блюдо
                  </label>
                  <div className="alt-box">
                    {alt.map((item, index) => (
                      <select
                        onChange={(event) => handleChange(event, index)}
                        className="add-event-repeat select-dish add-dish-style-big"
                        name={"dishes_ids"}
                        placeholder="Выберите категорию"
                      >
                        {!item && <option></option>}
                        {meal.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    ))}
                  </div>
                </div>

                <div className="btn-event-wrap btn-add-dish-wrap">
                  <img
                    onClick={handleClick}
                    src={plus}
                    alt="alt"
                    className="add-alter-dish"
                  />
                  <img
                    onClick={handleClickDelete}
                    src={minus}
                    alt="alt"
                    className="delete-alter-dish"
                  />
                  <button
                    type="button"
                    onClick={() => setAddDish(false)}
                    className="btn-event-cancel"
                  >
                    Отменить
                  </button>
                  <button
                    type="submit"
                    className={
                      !(dirty && isValid)
                        ? "btn-event-save"
                        : "btn-event-save active"
                    }
                  >
                    Сохранить
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ModalAddDish;
