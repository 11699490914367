import { useEffect, useState } from "react";
import HeaderAdmin from "../components/HeaderAdmin";
import SearchIcon from "./../images/search-icon.png";
import DishesCard from "../components/DishesCard";
import addPerson from "./../images/TableCell.png";
import ModalCreateDish from "../components/ModalCreateDish";
import ModalEditDish from "../components/ModalEditDish";
import ModalDeleteDish from "../components/ModalDeleteDish";
import { baseUrl, baseHeaders } from "../utils/utils";

function DishesList() {
  const [isOpen, setOpen] = useState(false);
  const [createDish, setCreateDish] = useState(false);
  const [editDish, setEditDish] = useState(false);
  const [deleteDish, setDeleteDish] = useState(false);
  const [dishes, setDishes] = useState([]);
  const [value, setValue] = useState("");

  function renderDishes() {
    fetch(`${baseUrl}/admin/dishes`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDishes(data.items);
      });
  }

  useEffect(() => {
    renderDishes();
  }, []);

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="search-course-wrap">
        <h1 className="dishes-title">Список блюд</h1>
        <i className="search-icon" aria-hidden="true">
          <img src={SearchIcon} alt="alt" />
        </i>
        <input
          onChange={(event) => setValue(event.target.value)}
          value={value}
          className="search-course"
          searchtype="text"
          placeholder="Найти блюдо"
        ></input>
      </div>

      <div className="dishes-list-wrap">
        <div className="dishes-header">
          <span className="dishes-name">Название</span>
          <span className="dishes-descr">Описание</span>
        </div>
        <div className="dishes-container">
          {dishes
            ?.filter((element) =>
              element.name.toLowerCase().includes(value.toLowerCase())
            )
            ?.map((item) => (
              <DishesCard
                item={item}
                setEditDish={setEditDish}
                setDeleteDish={setDeleteDish}
              />
            ))}
        </div>
        <div className="add-person">
          <span className="add-person-bg">
            <img
              src={addPerson}
              alt="add-person"
              onClick={() => setCreateDish(true)}
            />
          </span>
          <span className="bg"></span>
        </div>
      </div>
      <ModalCreateDish
        createDish={createDish}
        setCreateDish={setCreateDish}
        renderDishes={renderDishes}
      />
      <ModalEditDish
        editDish={editDish}
        setEditDish={setEditDish}
        dishes={dishes}
        setDishes={setDishes}
        renderDishes={renderDishes}
      />
      <ModalDeleteDish
        deleteDish={deleteDish}
        setDeleteDish={setDeleteDish}
        renderDishes={renderDishes}
        dishes={dishes}
        setDishes={setDishes}
      />
    </>
  );
}

export default DishesList;
