import { useNavigate } from "react-router-dom";
import eventImg from "./../images/event-img.png";
import { getTime } from "../utils/utils";
import cover from "./../images/cover.jpeg";

function EventCard({ item, setEditEvent }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="event-card-wrap">
        <img
          onClick={() => navigate(`/event_descr/${item.id}`)}
          className="event-img"
          src={cover}
          alt="event-img"
        />
        <span className="event-hour">
          {getTime(item.time_start, item.time_end)}ч
        </span>
        <span className="event-name">{item.name}</span>
        <span className="geo">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.6654 6.60606C12.6654 10.0606 7.9987 14.6667 7.9987 14.6667C7.9987 14.6667 3.33203 10.0606 3.33203 6.60606C3.33203 3.95621 5.42137 2 7.9987 2C10.576 2 12.6654 3.95621 12.6654 6.60606ZM7.9987 8.66667C9.10327 8.66667 9.9987 7.77124 9.9987 6.66667C9.9987 5.5621 9.10327 4.66667 7.9987 4.66667C6.89413 4.66667 5.9987 5.5621 5.9987 6.66667C5.9987 7.77124 6.89413 8.66667 7.9987 8.66667Z"
              fill="#4C4360"
            />
          </svg>
          {item.event_place}
        </span>
        <span className="geo-text">Место проведения</span>
        <span className="geo">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.6654 6.60606C12.6654 10.0606 7.9987 14.6667 7.9987 14.6667C7.9987 14.6667 3.33203 10.0606 3.33203 6.60606C3.33203 3.95621 5.42137 2 7.9987 2C10.576 2 12.6654 3.95621 12.6654 6.60606ZM7.9987 8.66667C9.10327 8.66667 9.9987 7.77124 9.9987 6.66667C9.9987 5.5621 9.10327 4.66667 7.9987 4.66667C6.89413 4.66667 5.9987 5.5621 5.9987 6.66667C5.9987 7.77124 6.89413 8.66667 7.9987 8.66667Z"
              fill="#4C4360"
            />
          </svg>
          {item.gathering_place}
        </span>
        <span className="geo-text">Место встречи</span>
        <button onClick={() => setEditEvent(item)} className="event-edit-btn">
          Редактировать
        </button>
      </div>
    </>
  );
}

export default EventCard;
