import React, { useState, useRef, useEffect } from "react";
import IconSelect from "./../images/IconSelect.svg";
import { useClickOutside } from "../hooks/useClickOutside";
import Modal from "./Modal";
import { baseUrl } from "../utils/utils";
import { Link, useNavigate } from "react-router-dom";

function HeaderAdmin({ isOpen, setOpen }) {
  const menuRef = useRef(null);
  useClickOutside(menuRef, () => {
    setOpen(false);
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [user, setUser] = useState({});
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${baseUrl}/admin/me`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.detail) {
          localStorage.removeItem("token");
        } else {
          setUser(data);
        }
      });
  }, []);

  return (
    <div className="header">
      {modalOpen && <Modal setModalOpen={setModalOpen} />}
      <div className="header_title" onClick={() => navigate(`/`)}>
        <div className="title">ГЛЭМПИНГ «ПОДВОРЬЕ»</div>
        <div className="subtitle">Административная панель</div>
      </div>

      <div className="feedback" onClick={() => navigate(`/feedback`)}>
        <svg
          className="feedback-icon"
          width="12"
          height="11"
          viewBox="0 0 12 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 8C12 8.55228 11.5534 9 11.0012 9C8.83495 9 3.77104 9 2.16667 9L0 11C0 9.21293 0 3.35421 0 0.996254C0 0.443969 0.447715 9.93136e-07 1 9.93136e-07H11C11.5523 9.93136e-07 12 0.447716 12 1V8Z"
            fill="#494B42"
          />
        </svg>
        Обратная связь
      </div>

      <div className="user_avatar">
        <span className="admin_name">
          {user?.first_name} {user?.surname}
        </span>
        <img
          onClick={() => setOpen(!isOpen)}
          className="icon_select"
          ref={menuRef}
          src={IconSelect}
          alt="select"
        />
      </div>
      <nav className={`dropdown-menu ${isOpen ? "active" : ""}`}>
        <ul className="dropdown-list">
          <Link to="/groups">
            <div className="test">
              <li className="dropdown-item">Группы</li>
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33423 7.80889C3.54084 7.61162 2.6087 5.74811 2.6087 3.91304C2.6087 1.97244 2.63284 0 5.65217 0C7.06938 0 7.82672 0.434544 8.23139 1.0964C7.96533 1.17349 7.71023 1.27197 7.46757 1.39433C6.695 1.7839 6.1529 2.36371 5.80373 3.03366C5.21328 4.16645 5.21567 5.5387 5.21718 6.39964L5.21731 6.52174C5.21731 6.94493 5.25513 7.37851 5.33423 7.80889Z"
                  fill="#877E99"
                />
                <path
                  d="M5.92105 9.56522H5.21747C1.9566 9.56522 0 11.0435 0 15.3913H2.86409C3.14015 14.1213 3.65128 13.2849 4.45167 12.4118C5.13436 11.6671 5.95148 11.167 6.84507 10.8561C6.64317 10.652 6.46097 10.4308 6.29917 10.1978C6.15861 9.99528 6.03277 9.78367 5.92105 9.56522Z"
                  fill="#877E99"
                />
                <path
                  d="M6.95644 6.52174C6.95644 8.46234 7.9989 10.4348 9.99992 10.4348C12.0009 10.4348 13.0434 8.46234 13.0434 6.52174C13.0434 4.58114 13.0192 2.6087 9.99992 2.6087C6.98059 2.6087 6.95644 4.58114 6.95644 6.52174Z"
                  fill="#877E99"
                />
                <path
                  d="M15.6521 18C15.6521 14.5217 14.4203 12.1739 10.4348 12.1739H9.56522C6.30435 12.1739 4.34775 13.6522 4.34775 18H15.6521Z"
                  fill="#877E99"
                />
                <path
                  d="M14.3478 0C12.9306 0 12.1733 0.43457 11.7686 1.09646C12.0346 1.17355 12.2896 1.272 12.5323 1.39433C13.3048 1.7839 13.8469 2.36371 14.1961 3.03366C14.7866 4.16645 14.7842 5.5387 14.7827 6.39964L14.7825 6.52174C14.7825 6.94495 14.7447 7.37857 14.6656 7.80894C16.4591 7.61177 17.3913 5.74816 17.3913 3.91304C17.3913 1.97244 17.3672 0 14.3478 0Z"
                  fill="#877E99"
                />
                <path
                  d="M15.9349 12.877C16.5458 13.7007 16.9215 14.3922 17.1379 15.3913H20C20 11.913 18.7682 9.56522 14.7827 9.56522H14.0788C13.9671 9.78367 13.8412 9.99528 13.7007 10.1978C13.5388 10.431 13.3564 10.6522 13.1544 10.8566C14.292 11.252 15.2291 11.9255 15.9349 12.877Z"
                  fill="#877E99"
                />
              </svg>
            </div>
          </Link>
          <Link to="/events">
            <div className="test">
              <li className="dropdown-item">Мероприятия</li>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 1.5C6.44772 1.5 6 1.94772 6 2.5V4H3C1.89543 4 1 4.89543 1 6V19C1 20.1046 1.89543 21 3 21H21C22.1046 21 23 20.1046 23 19V6C23 4.89543 22.1046 4 21 4H18V2.5C18 1.94772 17.5523 1.5 17 1.5H16C15.4477 1.5 15 1.94772 15 2.5V4H9V2.5C9 1.94772 8.55228 1.5 8 1.5H7ZM4.14286 10.0909H19.8571V17.9091H4.14286V10.0909Z"
                  fill="#877E99"
                />
              </svg>
            </div>
          </Link>
          <Link to="/menu">
            <div className="test">
              <li className="dropdown-item item-rest">Меню</li>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.886 2.625L20.8848 20.6415C21.2074 21.1723 21.5411 21.3764 21.8209 21.375C22.0986 21.3737 22.4287 21.1672 22.745 20.6523L21.8813 11.4502L21.8712 11.3381L21.9403 11.2487C22.9329 9.97519 23.2797 7.77492 22.9966 5.91389C22.855 4.98333 22.5587 4.14108 22.1437 3.54567C21.7957 3.04636 21.3806 2.72784 20.8861 2.625H20.886ZM3.43095 2.62519L3.4302 5.77177L2.84145 5.77102V2.62537H2.17091L2.171 5.77116H1.58141V2.62537H0.898438V7.46419C0.898391 7.99936 1.19047 8.29612 1.65031 8.47959L1.84062 8.55562L1.83641 8.76047C1.74266 12.7247 1.64998 16.6889 1.55694 20.653C1.87756 21.1777 2.20691 21.3763 2.48112 21.375C2.75534 21.3737 3.08441 21.1698 3.40095 20.6516C3.29244 16.6885 3.18392 12.7252 3.07559 8.76206L3.06997 8.55581L3.26089 8.48053C3.74483 8.28806 4.05959 7.96144 4.05959 7.46447V2.62519H3.43095ZM11.9991 4.45312C9.99754 4.45312 8.07796 5.24824 6.66265 6.66355C5.24733 8.07887 4.45222 9.99844 4.45222 12C4.45222 14.0016 5.24733 15.9211 6.66265 17.3364C8.07796 18.7518 9.99754 19.5469 11.9991 19.5469C14.0006 19.5469 15.9202 18.7518 17.3355 17.3364C18.7509 15.9211 19.546 14.0016 19.546 12C19.546 9.99844 18.7509 8.07887 17.3355 6.66355C15.9202 5.24824 14.0006 4.45312 11.9991 4.45312Z"
                  fill="#877E99"
                />
              </svg>
            </div>
          </Link>
          <Link to="/dishes">
            <div className="test">
              <li className="dropdown-item item-rest">Список блюд</li>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.886 2.625L20.8848 20.6415C21.2074 21.1723 21.5411 21.3764 21.8209 21.375C22.0986 21.3737 22.4287 21.1672 22.745 20.6523L21.8813 11.4502L21.8712 11.3381L21.9403 11.2487C22.9329 9.97519 23.2797 7.77492 22.9966 5.91389C22.855 4.98333 22.5587 4.14108 22.1437 3.54567C21.7957 3.04636 21.3806 2.72784 20.8861 2.625H20.886ZM3.43095 2.62519L3.4302 5.77177L2.84145 5.77102V2.62537H2.17091L2.171 5.77116H1.58141V2.62537H0.898438V7.46419C0.898391 7.99936 1.19047 8.29612 1.65031 8.47959L1.84062 8.55562L1.83641 8.76047C1.74266 12.7247 1.64998 16.6889 1.55694 20.653C1.87756 21.1777 2.20691 21.3763 2.48112 21.375C2.75534 21.3737 3.08441 21.1698 3.40095 20.6516C3.29244 16.6885 3.18392 12.7252 3.07559 8.76206L3.06997 8.55581L3.26089 8.48053C3.74483 8.28806 4.05959 7.96144 4.05959 7.46447V2.62519H3.43095ZM11.9991 4.45312C9.99754 4.45312 8.07796 5.24824 6.66265 6.66355C5.24733 8.07887 4.45222 9.99844 4.45222 12C4.45222 14.0016 5.24733 15.9211 6.66265 17.3364C8.07796 18.7518 9.99754 19.5469 11.9991 19.5469C14.0006 19.5469 15.9202 18.7518 17.3355 17.3364C18.7509 15.9211 19.546 14.0016 19.546 12C19.546 9.99844 18.7509 8.07887 17.3355 6.66355C15.9202 5.24824 14.0006 4.45312 11.9991 4.45312Z"
                  fill="#877E99"
                />
              </svg>
            </div>
          </Link>

          <div onClick={() => setModalOpen(true)} className="test">
            <li className="dropdown-item">Выйти</li>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 20L3 20L3 4L16 4V9L14 9V6H5V18L14 18V15H16V20Z"
                fill="#877E99"
              />
              <path
                d="M18 16L22 12L18 8V11L9 11L9 13L18 13V16Z"
                fill="#877E99"
              />
            </svg>
          </div>
        </ul>
      </nav>
    </div>
  );
}

export default HeaderAdmin;
