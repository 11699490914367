import { useLocation, useNavigate } from "react-router-dom";
import { parseDate } from "../utils/utils";
import unactiveGroup from "./../images/unactiveGroup.png";
import activeGroup from "./../images/active-group.png";

function CardGroupGray({ item }) {
  const navigate = useNavigate();
  const location = useLocation();

  function handleClick() {
    if (location.pathname == "/feedback") {
      navigate(
        `/feedback_descr/${item.id}/${item.start_date}/${item.end_date}`
      );
    } else if (location.pathname == "/guests_menu") {
      navigate(`/group_menu/${item.id}/${item.start_date}/${item.end_date}`);
    } else {
      navigate(`/group_list/${item.id}`);
    }
  }

  return (
    <div
      onClick={handleClick}
      className={
        !item.is_active
          ? "card-wrap-gray card-item"
          : "card-wrap-green card-item"
      }
    >
      <img
        className="group-img"
        src={!item.is_active ? unactiveGroup : activeGroup}
        alt="img"
      />
      <div className="card-info">
        <span className={!item.is_active ? "date-group-gray" : "date-group"}>
          {parseDate(item.start_date)} – {parseDate(item.end_date)}
        </span>
        {item.is_active ? <span className="status">Актуальный заезд</span> : ""}
      </div>
    </div>
  );
}

export default CardGroupGray;
