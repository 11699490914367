import { useState } from "react";
import EventCard from "./EventCard";
import { parseDate } from "../utils/utils";

function EventCardDate({ date, items, editEvent, setEditEvent }) {
  return (
    <>
      <div className="card-wrap-all">
        <div className="event-date">{parseDate(date)}</div>
        <div className="card-wrap">
          {items?.map((item) => (
            <EventCard
              item={item}
              editEvent={editEvent}
              setEditEvent={setEditEvent}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default EventCardDate;
