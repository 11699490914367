function PersonMenu({ item }) {
  return (
    <>
      <div className="person-list-wrap person-choice-wrap">
        <span className="settle-person">{item.user.house.number}</span>
        <span className="person-item person-item-fio item-text ">
          {item.user.last_name} {item.user.first_name} {item.user.surname}
        </span>
        <div className="breakfast-container">
          {item?.breakfast?.length ? (
            item?.breakfast?.map((elem) => (
              <span className="person-item person-item-mail item-text person-choice">
                {elem}
              </span>
            ))
          ) : (
            <span className="person-item person-item-start no-choice item-text">
              Не выбрано!
            </span>
          )}
        </div>
        <div className="lunch-container">
          {item?.lunch?.length ? (
            item?.lunch?.map((elem) => (
              <span className="person-item person-item-mail item-text">
                {elem}
              </span>
            ))
          ) : (
            <span className="person-item person-item-start no-choice item-text">
              Не выбрано!
            </span>
          )}
        </div>
        <div className="dinner-container">
          {item?.dinner?.length ? (
            item?.dinner?.map((elem) => (
              <span className="person-item person-item-end item-text">
                {elem}
              </span>
            ))
          ) : (
            <span className="person-item person-item-start no-choice item-text">
              Не выбрано!
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default PersonMenu;
