import { useEffect, useState } from "react";

function MenuCheckbox({ item, setIds, period, day }) {
  const [selected, setSelected] = useState(item.selected);
  console.log(selected);

  useEffect(() => {
    setSelected(item.selected);
  }, [item.selected, period, day]);

  function handleChange(event) {
    setSelected((prev) => !prev);
    if (event.target.checked) {
      setIds((prev) => [...prev, item.id]);
    } else {
      setIds((prev) => prev.filter((id) => id !== item.id));
    }
  }

  return (
    <div className="for-all-card-dish">
      <span className="for-all-card-name">{item.name}</span>
      <input
        checked={selected}
        onChange={handleChange}
        className="for-all-checkbox"
        type="checkbox"
      />
    </div>
  );
}

export default MenuCheckbox;
