import { useNavigate } from "react-router-dom";
import { parseDate } from "../utils/utils";
import deleteItem from "./../images/delete-item.png";

function CardPerson({ item, setDelPerson }) {
  const navigate = useNavigate();

  return (
    <div className="person-list-wrap">
      <span className="settle-person">{item.house?.number}</span>
      <span
        className="person-item person-item-fio"
        onClick={() => navigate(`/person_descr/${item.id}`)}
      >
        {item.last_name} {item.first_name} {item.surname}
      </span>
      <span className="person-item person-item-mail">
        {item.id}
        {item.access_code}
      </span>
      <span className="person-item person-item-start">
        {parseDate(item.start_date)}
      </span>
      <span className="person-item person-item-end">
        {parseDate(item.end_date)}
      </span>
      <span onClick={() => setDelPerson(item.id)} className="delete-person">
        <img className="delete-person" src={deleteItem} alt="delete" />
      </span>
    </div>
  );
}

export default CardPerson;
