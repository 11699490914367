import { translateMealType } from "../utils/utils";

function MenuChoice({ setCancelDish, item }) {
  return (
    <div className="choice-card">
      <svg
        onClick={() => setCancelDish(item)}
        className="del-card"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 4L4 6L10 12L4 18L6 20L12 14L18 20L20 18L14 12L20 6L18 4L12 10L6 4Z"
          fill="#E64D4D"
        />
      </svg>

      <span className="cat-choice">
        {translateMealType[item.dishes[0].dish_category_id]}
      </span>
      {item.dishes.map((item) => (
        <span className="cat-choice-item">{item.name}</span>
      ))}
    </div>
  );
}

export default MenuChoice;
