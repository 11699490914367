import React from "react";

function Dishes() {
  return (
    <div className="rest">
      <span className="menu-title">Список блюд</span>
      <span className="menu-edit">Создать/редактировать</span>
    </div>
  );
}

export default Dishes;
